import { defineStore } from 'pinia'

interface DashboardCache {
  data: DashboardResponse | null
  timestamp: number | null
}

export const useDashboardCacheStore = defineStore('dashboardCache', {
  state: (): DashboardCache => ({
    data: null,
    timestamp: null,
  }),
  actions: {
    setDashboardData(data: DashboardResponse) {
      this.data = data
      this.timestamp = Date.now()
    },
    clearCache() {
      this.data = null
      this.timestamp = null
    },
  },
  getters: {
    isValidCache(): boolean {
      if (!this.data || !this.timestamp) return false
      const currentTime = Date.now()
      const cacheAge = currentTime - this.timestamp
      return cacheAge < 60000 // 1 minute in milliseconds
    },
  },
})