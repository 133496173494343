import { default as _91event_id_9373lOrIFSl8Meta } from "/tmp/build_028f36db/pages/events/[event_id].vue?macro=true";
import { default as experiencesvOr1MgsmKQMeta } from "/tmp/build_028f36db/pages/experiences.vue?macro=true";
import { default as indexYiHoLIwEDVMeta } from "/tmp/build_028f36db/pages/index.vue?macro=true";
import { default as events_45slider_45exampleIS8Ouea6GhMeta } from "/tmp/build_028f36db/pages/widgets/events-slider-example.vue?macro=true";
import { default as events_45sliderClgksOfYjnMeta } from "/tmp/build_028f36db/pages/widgets/events-slider.vue?macro=true";
export default [
  {
    name: "events-event_id",
    path: "/events/:event_id()",
    component: () => import("/tmp/build_028f36db/pages/events/[event_id].vue")
  },
  {
    name: "experiences",
    path: "/experiences",
    meta: experiencesvOr1MgsmKQMeta || {},
    component: () => import("/tmp/build_028f36db/pages/experiences.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_028f36db/pages/index.vue")
  },
  {
    name: "widgets-events-slider-example",
    path: "/widgets/events-slider-example",
    meta: events_45slider_45exampleIS8Ouea6GhMeta || {},
    component: () => import("/tmp/build_028f36db/pages/widgets/events-slider-example.vue")
  },
  {
    name: "widget-events-slider",
    path: "/widgets/events-slider",
    meta: events_45sliderClgksOfYjnMeta || {},
    component: () => import("/tmp/build_028f36db/pages/widgets/events-slider.vue")
  }
]