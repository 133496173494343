import { useFetch } from '#app'
import { useDashboardCacheStore } from '@/stores/dashboardCache'
import Cookies from 'js-cookie';

export function useApi() {
  const config = useRuntimeConfig()
  const baseUrl = config.public.BASE_API_URL
  const dashboardCacheStore = useDashboardCacheStore()

  const accessTokenProxy = useCookie('wellT');

  function getHeaders() {

    let accessTokenValue;
    if(process.client) {
      accessTokenValue = Cookies.get('wellT');
    } else {
      accessTokenValue = accessTokenProxy.value;
    }
    return {
      'Authorization': `Token ${accessTokenValue}`
    }
  }

  const fetchCategories = async () => await useFetch<EventCategoriesResponse[]>(`${baseUrl}/api/event-categories`)
  const fetchDashboard = async () => {
    if (dashboardCacheStore.isValidCache) {
      return { data: {value: dashboardCacheStore.data}, error: null }
    }

    const response = await useFetch<DashboardResponse>(`${baseUrl}/api/wellwellwell/events/dashboard`)
    
    if (response.data.value) {
      dashboardCacheStore.setDashboardData(response.data.value)
    }

    return response
  }
  const fetchEvent = async (eventId: number) => await useFetch<EventResponse>(`${baseUrl}/api/wellwellwell/events/${eventId}`, {
    headers: getHeaders()
  })
  const getEvent = async (eventId: number) => await $fetch<EventResponse>(`${baseUrl}/api/wellwellwell/events/${eventId}`, {
    headers: getHeaders()
  })
  const fetchSimilarEvents = async (eventId: number): Promise<EventResponse[]> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/similar_events`);
  }
  const fetchInstructorEvents = async (instructorId: number): Promise<EventResponse[]> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/events_by_instructor`,  {
      method: 'POST',
      body: JSON.stringify({ instructor_id: instructorId })
    });
  }
  const fetchEvents = async (query: any): Promise<EventResponse[]> => {
    const categories = query.categories || []
    delete query.categories
    const urlSearchParams = new URLSearchParams(query)
    categories.forEach((category: number) => urlSearchParams.append('categories', category.toString()))

    return await $fetch(`${baseUrl}/api/wellwellwell/events?${urlSearchParams.toString()}`);
  }

  // New function to create a payment intent for an event
  const createPaymentIntent = async (token: string, eventId: number, email: string, firstName: string, lastName: string): Promise<PaymentIntentResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/payment_intent`, {
      method: 'POST',
      body: JSON.stringify({ token, email, first_name: firstName, last_name: lastName }),
      headers: getHeaders()
    });
  }

  // New function to register for an event
  const registerForEvent = async (email: string, firstName: string, lastName: string, token: string, marketingOptin: boolean, eventId: number, paymentIntentId: string, paymentMethodId: string): Promise<EventRegistrationResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/register`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        first_name: firstName,
        last_name: lastName,
        token, 
        marketing_optin: marketingOptin,
        payment_intent_id: paymentIntentId, 
        payment_method_id: paymentMethodId,
      }),
      headers: getHeaders()
    });
  }

  const disableEvent = async (eventId: number, wwwAdminReviewNotes: string): Promise<EventResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/disable`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        www_admin_review_notes: wwwAdminReviewNotes
      })
    });
  }

  const toggleFeaturedEvent = async (eventId: number, featured: boolean): Promise<EventResponse> => {
    return await $fetch(`${baseUrl}/api/wellwellwell/events/${eventId}/toggle_featured`, {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ featured })
    });
  }

  const login = async (email: string, password: string): Promise<LoginResponse> => {
    return await $fetch(`${baseUrl}/auth/login/`, {
      method: 'POST',
      body: JSON.stringify({ email, password })
    });
  }

  const googleLogin = async (accessToken: string): Promise<LoginResponse> => {
    return await $fetch(`${baseUrl}/auth/google/`, {
      method: 'POST',
      body: JSON.stringify({ access_token: accessToken }),
    });
  }

  const facebookLogin = async (accessToken: string): Promise<LoginResponse> => {
    return await $fetch(`${baseUrl}/auth/facebook/`, {
      method: 'POST',
      body: JSON.stringify({ access_token: accessToken }),
    });
  }

  const fetchUserInfo = async () => await useFetch(`${baseUrl}/api/students/me`, { headers: getHeaders() })

  const getUserInfo = async (): Promise<UserResponse> => {
    return await $fetch(`${baseUrl}/api/students/me`, {
      headers: getHeaders()
    });
  }

  return {
    fetchCategories,
    fetchDashboard,
    fetchEvents,
    fetchEvent,
    getEvent,
    fetchSimilarEvents,
    fetchInstructorEvents,
    createPaymentIntent,
    registerForEvent,
    disableEvent,
    toggleFeaturedEvent,
    login,
    fetchUserInfo,
    getUserInfo,
    googleLogin,
    facebookLogin
  }
}
